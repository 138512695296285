import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';
// import { join } from 'path';

const unitTesting = !!process.env.VITEST;

// if (unitTesting) {
//   const { join } = require('path');
//   const dotenv = require('dotenv');
//
//   dotenv.config({
//     path: join(__dirname, './.env.test'),
//   });
// }

const nodeEnv = process.env.NODE_ENV;

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    PROD_COPY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    NEXT_RUNTIME: z.enum(['nodejs', 'edge', 'browser']),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    ENABLE_CRONS: z.string().optional(),
    ENABLE_POSTHOG_INNGEST_EVENTS: z.string().optional(),
    CI: z.string().optional(),
    DATABASE_HOST: ['production'].includes(nodeEnv)
      ? z.string().min(1)
      : z.string().optional(),
    DATABASE_USERNAME: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    DATABASE_PASSWORD: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    UPSTASH_KAFKA_REST_URL: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    UPSTASH_KAFKA_REST_USERNAME: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    UPSTASH_KAFKA_REST_PASSWORD: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TB_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TB_SOURCE_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    BRANCH: z.string().optional().default('local'),
    ONEDAY_IMPACTRADIUS_API_PASS: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    FOURTEENDAY_IMPACT_RADIUS_API_PASS: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    APPLE_SERVICE_PARTNERIZE_BASELINE_CREDENTIALS: ['production'].includes(
      nodeEnv,
    )
      ? z.string().optional()
      : z.string().optional(),
    APPLE_SERVICE_PARTNERIZE_ELEVATED_CREDENTIALS: ['production'].includes(
      nodeEnv,
    )
      ? z.string().optional()
      : z.string().optional(),
    CJ_CREATOR_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_CREATOR_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_PUBLISHER_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_PUBLISHER_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_DEAL_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_DEAL_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_HOWL_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CJ_HOWL_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    BUTTON_SAMSUNG_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    BUTTON_SAMSUNG_API_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    AWIN_ACCESS_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    NARRATIV_AWIN_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    PARTNERIZE_USER_CREDENTIALS: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    PARTNERIZE_HOWL_PUBLISHER_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    AWS_ACCESS_KEY_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    AWS_SECRET_ACCESS_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    AWS_DEFAULT_REGION: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    POLYGON_API_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MODERN_TREASURY_API_KEY: ['production'].includes(nodeEnv)
      ? z.string()
      : z.string(),
    MODERN_TREASURY_ORGANIZATION_ID: ['production'].includes(nodeEnv)
      ? z.string()
      : z.string(),
    MODERN_TREASURY_LEDGER_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MT_HOWL_UNRECEIVED_NET_REVENUE_LEDGER_ACCOUNT_ID: ['production'].includes(
      nodeEnv,
    )
      ? z.string().optional()
      : z.string().optional(),
    MT_HOWL_RECEIVED_NET_REVENUE_LEDGER_ACCOUNT_ID: ['production'].includes(
      nodeEnv,
    )
      ? z.string().optional()
      : z.string().optional(),
    MT_HOWL_CASH_RECEIVED_LEDGER_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MT_HOWL_FLAT_RATE_LEDGER_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MT_HOWL_DEBUG_INTERNAL_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MT_HOWL_INTERNAL_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    FACEBOOK_CLIENT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    FACEBOOK_CLIENT_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TWITCH_CLIENT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TWITCH_CLIENT_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    X_CLIENT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    X_CLIENT_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TIKTOK_CLIENT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TIKTOK_CLIENT_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    YOUTUBE_CLIENT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    YOUTUBE_CLIENT_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    RESEND_EMAIL_API_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    VERCEL_URL: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    R2_SECRET_ACCESS_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    R2_ACCESS_KEY_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    R2_API_ENDPOINT: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MIN_FOLLOWERS: ['production'].includes(nodeEnv)
      ? z.number().optional()
      : z.number().optional(),
    USER_ID_FORCE_CHECK: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    ALLOW_INTERNAL_CREATORS: ['production'].includes(nodeEnv)
      ? z.boolean().optional()
      : z.boolean().optional(),
    CF_IMAGES_TOKEN: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CF_ACCOUNT_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    CLERK_SECRET_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    SERPAPI_API_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TEST_AUTH_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TARGET_ORG_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    ROUNDEL_CAMPAIGN_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TARGET_PRODUCT_FEED_HOST: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TARGET_PRODUCT_FEED_USER: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TARGET_PRODUCT_FEED_PASSWORD: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    TARGET_PRODUCT_FEED_FILE_PATH: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    R2_MEDIA_BUCKET_URL: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MUX_TOKEN_ID: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    MUX_TOKEN_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    FLAGS_SECRET: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    INVITE_LINK_DISABLED: ['production'].includes(nodeEnv)
      ? z.boolean().optional()
      : z.boolean().optional(),
    EDGE_CONFIG: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    INNGEST_SIGNING_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    PROD_COPY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    // NEXT_PUBLIC_CLIENTVAR: z.string(),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    NEXT_PUBLIC_POSTHOG_KEY: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    NEXT_PUBLIC_POSTHOG_HOST: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    NEXT_PUBLIC_MODERN_TREASURY_PUBLISHABLE_KEY: ['production'].includes(
      nodeEnv,
    )
      ? z.string().optional()
      : z.string().optional(),
    NEXT_PUBLIC_HOWL_LINK_URL: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    OTEL_EXPORTER_OTLP_HEADERS: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    OTEL_EXPORTER_OTLP_ENDPOINT: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
    OTEL_SERVICE_NAME: ['production'].includes(nodeEnv)
      ? z.string().optional()
      : z.string().optional(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    PROD_COPY: process.env.PROD_COPY,
    VERCEL_URL: process.env.VERCEL_URL,
    NEXT_RUNTIME: process.env.NEXT_RUNTIME,
    NODE_ENV: process.env.NODE_ENV,
    ENABLE_CRONS: process.env.ENABLE_CRONS,
    ENABLE_POSTHOG_INNGEST_EVENTS:
      process.env.ENABLE_POSTHOG_INNGEST_EVENTS === '1' ? '1' : undefined,
    CI: process.env.CI,
    DATABASE_HOST: process.env.DATABASE_HOST,
    DATABASE_USERNAME: process.env.DATABASE_USERNAME,
    DATABASE_PASSWORD: process.env.DATABASE_PASSWORD,
    UPSTASH_KAFKA_REST_URL: process.env.UPSTASH_KAFKA_REST_URL,
    UPSTASH_KAFKA_REST_USERNAME: process.env.UPSTASH_KAFKA_REST_USERNAME,
    UPSTASH_KAFKA_REST_PASSWORD: process.env.UPSTASH_KAFKA_REST_PASSWORD,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    TB_TOKEN: process.env.TB_TOKEN,
    TB_SOURCE_TOKEN: process.env.TB_SOURCE_TOKEN,
    BRANCH: process.env.VERCEL_GIT_COMMIT_REF,
    ONEDAY_IMPACTRADIUS_API_PASS: process.env.ONEDAY_IMPACTRADIUS_API_PASS,
    FOURTEENDAY_IMPACT_RADIUS_API_PASS:
      process.env.FOURTEENDAY_IMPACT_RADIUS_API_PASS,
    APPLE_SERVICE_PARTNERIZE_BASELINE_CREDENTIALS:
      process.env.APPLE_SERVICE_PARTNERIZE_BASELINE_CREDENTIALS,
    APPLE_SERVICE_PARTNERIZE_ELEVATED_CREDENTIALS:
      process.env.APPLE_SERVICE_PARTNERIZE_ELEVATED_CREDENTIALS,
    CJ_CREATOR_ACCOUNT_ID: process.env.CJ_CREATOR_ACCOUNT_ID,
    CJ_CREATOR_TOKEN: process.env.CJ_CREATOR_TOKEN,
    CJ_PUBLISHER_ACCOUNT_ID: process.env.CJ_PUBLISHER_ACCOUNT_ID,
    CJ_PUBLISHER_TOKEN: process.env.CJ_PUBLISHER_TOKEN,
    CJ_DEAL_ACCOUNT_ID: process.env.CJ_DEAL_ACCOUNT_ID,
    CJ_DEAL_TOKEN: process.env.CJ_DEAL_TOKEN,
    CJ_HOWL_ACCOUNT_ID: process.env.CJ_HOWL_ACCOUNT_ID,
    CJ_HOWL_TOKEN: process.env.CJ_HOWL_TOKEN,
    BUTTON_SAMSUNG_ACCOUNT_ID: process.env.BUTTON_SAMSUNG_ACCOUNT_ID,
    BUTTON_SAMSUNG_API_KEY: process.env.BUTTON_SAMSUNG_API_KEY,
    AWIN_ACCESS_TOKEN: process.env.AWIN_ACCESS_TOKEN,
    NARRATIV_AWIN_ID: process.env.NARRATIV_AWIN_ID,
    PARTNERIZE_USER_CREDENTIALS: process.env.PARTNERIZE_USER_CREDENTIALS,
    PARTNERIZE_HOWL_PUBLISHER_ID: process.env.PARTNERIZE_HOWL_PUBLISHER_ID,
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    AWS_DEFAULT_REGION: process.env.AWS_DEFAULT_REGION,
    POLYGON_API_KEY: process.env.POLYGON_API_KEY,
    MODERN_TREASURY_API_KEY: process.env.MODERN_TREASURY_API_KEY,
    MODERN_TREASURY_ORGANIZATION_ID:
      process.env.MODERN_TREASURY_ORGANIZATION_ID,
    MODERN_TREASURY_LEDGER_ID: process.env.MODERN_TREASURY_LEDGER_ID,
    MT_HOWL_UNRECEIVED_NET_REVENUE_LEDGER_ACCOUNT_ID:
      process.env.MT_HOWL_UNRECEIVED_NET_REVENUE_LEDGER_ACCOUNT_ID,
    MT_HOWL_RECEIVED_NET_REVENUE_LEDGER_ACCOUNT_ID:
      process.env.MT_HOWL_RECEIVED_NET_REVENUE_LEDGER_ACCOUNT_ID,
    MT_HOWL_CASH_RECEIVED_LEDGER_ACCOUNT_ID:
      process.env.MT_HOWL_CASH_RECEIVED_LEDGER_ACCOUNT_ID,
    MT_HOWL_FLAT_RATE_LEDGER_ACCOUNT_ID:
      process.env.MT_HOWL_FLAT_RATE_LEDGER_ACCOUNT_ID,
    MT_HOWL_DEBUG_INTERNAL_ACCOUNT_ID:
      process.env.MT_HOWL_DEBUG_INTERNAL_ACCOUNT_ID,
    MT_HOWL_INTERNAL_ACCOUNT_ID: process.env.MT_HOWL_INTERNAL_ACCOUNT_ID,
    NEXT_PUBLIC_MODERN_TREASURY_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_MODERN_TREASURY_PUBLISHABLE_KEY,
    FACEBOOK_CLIENT_ID: process.env.FACEBOOK_CLIENT_ID,
    FACEBOOK_CLIENT_SECRET: process.env.FACEBOOK_CLIENT_SECRET,
    TWITCH_CLIENT_ID: process.env.TWITCH_CLIENT_ID,
    TWITCH_CLIENT_SECRET: process.env.TWITCH_CLIENT_SECRET,
    X_CLIENT_ID: process.env.X_CLIENT_ID,
    X_CLIENT_SECRET: process.env.X_CLIENT_SECRET,
    TIKTOK_CLIENT_ID: process.env.TIKTOK_CLIENT_ID,
    TIKTOK_CLIENT_SECRET: process.env.TIKTOK_CLIENT_SECRET,
    YOUTUBE_CLIENT_ID: process.env.YOUTUBE_CLIENT_ID,
    YOUTUBE_CLIENT_SECRET: process.env.YOUTUBE_CLIENT_SECRET,
    RESEND_EMAIL_API_KEY: process.env.RESEND_EMAIL_API_KEY,
    R2_SECRET_ACCESS_KEY: process.env.R2_SECRET_ACCESS_KEY,
    R2_ACCESS_KEY_ID: process.env.R2_ACCESS_KEY_ID,
    R2_API_ENDPOINT: process.env.R2_API_ENDPOINT,
    MIN_FOLLOWERS: process.env.MIN_FOLLOWERS
      ? Number(process.env.MIN_FOLLOWERS)
      : 1000,
    USER_ID_FORCE_CHECK: process.env.USER_ID_FORCE_CHECK,
    ALLOW_INTERNAL_CREATORS: !!process.env.ALLOW_INTERNAL_CREATORS,
    CF_IMAGES_TOKEN: process.env.CF_IMAGES_TOKEN,
    CF_ACCOUNT_ID: process.env.CF_ACCOUNT_ID,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    SERPAPI_API_KEY: process.env.SERPAPI_API_KEY,
    TEST_AUTH_SECRET: process.env.TEST_AUTH_SECRET,
    NEXT_PUBLIC_HOWL_LINK_URL: process.env.NEXT_PUBLIC_HOWL_LINK_URL,
    TARGET_ORG_ID: process.env.TARGET_ORG_ID,
    ROUNDEL_CAMPAIGN_ID: process.env.ROUNDEL_CAMPAIGN_ID,
    TARGET_PRODUCT_FEED_HOST: process.env.TARGET_PRODUCT_FEED_HOST,
    TARGET_PRODUCT_FEED_USER: process.env.TARGET_PRODUCT_FEED_USER,
    TARGET_PRODUCT_FEED_PASSWORD: process.env.TARGET_PRODUCT_FEED_PASSWORD,
    TARGET_PRODUCT_FEED_FILE_PATH: process.env.TARGET_PRODUCT_FEED_FILE_PATH,
    R2_MEDIA_BUCKET_URL: process.env.R2_MEDIA_BUCKET_URL,
    MUX_TOKEN_ID: process.env.MUX_TOKEN_ID,
    MUX_TOKEN_SECRET: process.env.MUX_TOKEN_SECRET,
    FLAGS_SECRET: process.env.FLAGS_SECRET,
    INVITE_LINK_DISABLED: process.env.INVITE_LINK_DISABLED === 'true',
    EDGE_CONFIG: process.env.EDGE_CONFIG,
    INNGEST_SIGNING_KEY: process.env.INNGEST_SIGNING_KEY,
    OTEL_EXPORTER_OTLP_HEADERS: process.env.OTEL_EXPORTER_OTLP_HEADERS,
    OTEL_EXPORTER_OTLP_ENDPOINT: process.env.OTEL_EXPORTER_OTLP_HEADERS,
    OTEL_SERVICE_NAME: process.env.OTEL_EXPORTER_OTLP_HEADERS,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION || unitTesting,
  /**
   * Makes it so that empty strings are treated as undefined.
   * `SOME_VAR: z.string()` and `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});
